import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Layout } from "../layouts";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

// markup
const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Page not found</title>
        </Helmet>
        <Wrapper>
          <h1>There's nothing here</h1>
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br />
          </p>
          <p>
            Click on one of the links at the top of the page or go{" "}
            <Link to={"/"}>home</Link>.
          </p>
        </Wrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;
